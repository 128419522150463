import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Footer from '@gtg/components/footer'
import Nav from '@gtg/components/nav'
import Seo from '@gtg/components/seo'
import React from 'react'
import GlobalWrapper from '../components/globalWrapper'
import styled from 'styled-components'

const Indent1 = styled.div`
  padding: 0 0 32px 20px;
`

const Indent2 = styled.div`
  padding: 0 0 32px 32px;
`

const Zustimmungserklaerung = () => {
  return (
    <GlobalWrapper>
      <Seo title="AGB" />
      <Nav caps wMax="xl" withBorder />
      <section>
        <Container>
          <Box pxMax="md" pyMax="lg">
            <h1>Zustimmungserklärung</h1>
            <p>
              Sofern Sie wünschen, dass wir sofort mit der Ausführung der
              beauftragten Dienstleistung beginnen, also vor Ablauf der
              vierzehntägigen Widerrufsfrist, teilen Sie uns das bitte per
              E-Mail, Fax oder Brief mit.
            </p>

            <p>
              Bitte bestätigen Sie ferner, dass wir Sie darüber informiert
              haben, dass Sie gemäß § 356 Abs. 4 BGB in diesem Fall bei
              vollständiger Vertragserfüllung Ihr Recht zum Widerruf verlieren.
            </p>

            <p>
              Sie können für Ihre Erklärung auch das nachfolgende Formular
              verwenden.:
            </p>
            <hr />
            <p>
              Erklärung über das Widerrufsrecht gemäß §§ 356 Abs. 4, 357 Abs. 8
              BGB
            </p>
            <p>Auftraggeber (Name, Anschrift, Tel., E-Mail):</p>
            <p>_____________________</p>
            <p>_____________________</p>
            <p>_____________________</p>
            <p>_____________________</p>
            <p>
              [    ]  Ich wünsche ausdrücklich, dass Sie vor Ende der 14-tägigen
              Widerrufsfrist mit der Ausführung der beauftragten Dienstleistung
              beginnen.
            </p>

            <p>
              [    ]  Ich wurde darüber in Kenntnis gesetzt, dass ich bei
              vollständiger Vertragserfüllung durch Sie mein Recht zum
              Widerrufsrecht verliere.
            </p>

            <p>*bitte ankreuzen</p>

            <p>__________</p>
            <p>Datum</p>
            <p>________________________________________________</p>
            <p>
              Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf
              Papier)
            </p>

            <hr />

            <p>Bitte senden Sie diese Erklärung per E-Mail an uns zurück.</p>

            <p>E-Mail: info[ät]stretchzeltbayern.de</p>
            <p>
              Hytide GmbH
              <br />
              Türkenstr. 5b<br />
              85748 Garching
            </p>
          </Box>
        </Container>
      </section>
      <Footer background="#0c1821" />
    </GlobalWrapper>
  )
}

export default Zustimmungserklaerung
